<template>
  <div class="flex items-center min-h-screen bg-gray-50 dark:bg-gray-900">
    <div class="container">
      <div class="lg:w-5/6 mx-auto my-10 bg-white py-5 rounded-md shadow-sm">
        <div class="row">
          <div class="col-md-12 ml-5 flex items-center">
            <img
              src="../../../assets/icons/Oonpay logo.png"
              height="80px"
              width="80px"
              alt=""
            />
            <span class="ml-4 font-bold text-xl"
              >Share Your Fund-Raising Campaign</span
            >
          </div>
        </div>

        <hr class="new4" />

        <div class="row">
          <div class="col-md-12 flex justify-center">
            <h4>Share On Your Social Media network</h4>
          </div>
        </div>
        <div class="row flex justify-center py-3">
          <div class="col-md-10 flex justify-around">
            <span
              class="iconify social"
              data-icon="ion:logo-facebook"
              data-inline="false"
            ></span>
            <span
              class="iconify social"
              data-icon="zmdi:twitter"
              data-inline="false"
            ></span>
            <span
              class="iconify social"
              data-icon="teenyicons:instagram-solid"
              data-inline="false"
            ></span>
            <span
              class="iconify"
              data-icon="uiw:linkedin"
              data-inline="false"
            ></span>
            <span
              class="iconify"
              data-icon="logos:whatsapp"
              data-inline="false"
            ></span>
            <span
              class="iconify"
              data-icon="zmdi:email"
              data-inline="false"
            ></span>
          </div>
        </div>

        <hr class="new4" />

        <div class="row px-24">
          <div class="col-md-12">
            <h4>Fund-Raising Campaign Link</h4>
          </div>
          <div class="col-md-8">
            <input
              type="text"
              placeholder="https://www.oonpay.com/adehelpfund"
              class="form-control"
            />
          </div>
          <div class="col-md-4">
            <button class="btn btn-primary">Copy</button>
          </div>
        </div>

        <hr class="new4" />
        <div class="row">
          <div class="col-md-4">
            <h5 class="text-center">
              Shareable
              <span class="text-oonpaysecondary font-bold text-2xl"
                >#FUNDTAG-QR-CODE</span
              >
            </h5>

            <p class="text-center py-2">QRCodes Here</p>
          </div>
          <div class="col-md-4 text-center space-y-5 vl">
            <h5>Download QR-Code</h5>

            <div class="form-check">
              <input type="checkbox" class="form-check-input" />
              <label class="form-check-label" for="png">png</label>
            </div>

            <div class="form-check">
              <input type="checkbox" class="form-check-input" />
              <label class="form-check-label" for="png">jpeg</label>
            </div>

            <div class="form-check">
              <input type="checkbox" class="form-check-input" />
              <label class="form-check-label" for="png">pdf</label>
            </div>

            <button class="btn btn-primary">Download</button>
          </div>
          <div class="col-md-4 space-y-5 px-4">
            <h5>Email QR-Code</h5>

            <div class="form-group">
              <input
                type="text"
                class="form-control"
                placeholder="qrobert@gmail.com"
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                placeholder="qrobert@gmail.com"
              />
            </div>
            <!-- <div class="row" v-for="(row ,key) in rows" :key="key"> -->
            <!-- <div class="input-field col s12 m12 l12">
                <input
                  type="text"
                  class="form-control"
                  :name="row.inputname"
                  v-model="row.inputvalue"
                  placeholder="eieii"
                />
              </div>
            </div>

            <button
              v-if="currentInputIndex < inputs.length"
              v-on:click="addRow"
            >
              Add
            </button> -->

            <!-- <button @click="submit" class="btn btn-primary">Submit</button> -->
          </div>
        </div>
        <hr class="new4" />

        <div class="flex justify-center py-4">
          <button @click="next" class="btn px-5 btn-primary">Next</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    next() {
      this.$router.push("/crowdhub-dashboard");
    },
  },
  //   data() {
  //     return {
  //       currentInputIndex: 0,
  //       inputs: ["email", "email","email","email"],
  //       rows: [],
  //     };
  //   },
  //   methods:{
  //       addRow: function() {
  //       this.rows.push({inputname: this.inputs[this.currentInputIndex], inputvalue: ""});
  //       this.currentInputIndex++;
  //     },
  //     submit(){
  //         console.log(this.rows.inputvalue)
  //     }
  //   }
};
</script>

<style  scoped>
.iconify {
  font-size: 38px;
}

.vl {
  border-left: 3px solid gray;
  border-right: 3px solid gray;
}

a:hover {
  text-decoration: none;
}

hr.new4 {
  border: 1px solid #ccc;
}

.btn-primary {
  background: #0c4369;
  border: #0c4369;
}
</style>